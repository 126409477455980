<template>
  <div class="ManageFirm-container">
    <el-form ref="manageFirmForm" :model="form" label-width="80px" class="form">
      <el-form-item label="经营方ID" prop="firmId">
        <el-input v-model="form.firmId" placeholder="请输经营方ID"></el-input>
      </el-form-item>
      <el-form-item label="经营方名称" label-width="100px" prop="firmName">
        <el-input v-model="form.firmName" placeholder="请输入经营方名称"></el-input>
      </el-form-item>
      <el-form-item class="buttonClass">
        <el-button icon="el-icon-search" type="primary" @click="getDataCX">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="manageTable"
      row-key="firmId"
      border
      element-loading-text="拼命加载中"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      :data="tableData"
      :header-cell-class-name="leftheaderStyle"
      class="managefirmstyle"
    >
      <el-table-column type="selection" reserve-selection width="55"></el-table-column>
      <el-table-column prop="firmId" label="经营方ID"></el-table-column>
      <el-table-column prop="firmName" label="经营方名称"></el-table-column>
    </el-table>

    <!-- 翻页 -->
    <el-pagination
      class="pagination"
      background
      layout="total, prev, pager, next, sizes, jumper"
      :total="total"
      :page-sizes="[10, 50, 100, 200]"
      @size-change="handleSizeChange"
      @current-change="pageChange"
      :page-size="pageSize"
      :current-page="pageNumber"
    ></el-pagination>
  </div>
</template>
<script>
import { operateList, addStoreFirm } from '@/api/operateParty';
export default {
  name: 'ManageFirm',
  props: {
    storeId: {
      required: true,
      default: 1,
      type: Number,
    },
    isDisable: {
      required: true,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      form: {
        firmId: '',
        firmName: '',
      },
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      loading: false,
      total: 1,
      firmId: 1,
    };
  },
  methods: {
    // 关闭弹框
    newReset() {
      this.form = {
        firmId: '',
        firmName: '',
      };
      this.pageNumber = 1;
      this.pageSize = 10;
    },
    // 多选按钮
    leftheaderStyle({ columnIndex }) {
      if (columnIndex === 0) {
        return 'seltAllbtnDis';
      }
    },
    reset() {
      // this.$refs.manageFirmForm.resetFields()
      this.form = {
        firmId: '',
        firmName: '',
      };
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getData();
    },
    // 翻页
    pageChange(e) {
      // this.pageNum = val
      this.pageNumber = e;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    async connectFirm() {
      const data = {
        storeId: this.storeId,
        firmId: this.firmId,
      };
      try {
        const res = await addStoreFirm({ data });
        res.success && this.$emit('closeManageFirm');
      } catch (error) {
        console.log(error);
      }
    },
    handleSelectionChange(e) {
      this.firmIds = [];
      if (e.length > 1) {
        this.$refs.manageTable.clearSelection();
        this.$refs.manageTable.toggleRowSelection(e[e.length - 1]);
      } else {
        this.firmId = '';
      }

      if (e[e.length - 1]) {
        this.firmId = e[e.length - 1].firmId;
      }
    },
    getDataCX() {
      this.pageNumber = 1;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const data = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          data: {
            ...this.form,
            neStoreId: this.storeId,
            isDisable: this.isDisable,
          },
        };
        const res = await operateList(data);
        const { content, total } = res.data;
        this.total = +total;
        this.tableData = content || [];
        this.tableData.map((a) => {
          if (a.isRelationStore == 1) {
            this.$nextTick(() => {
              this.$refs.manageTable.toggleRowSelection(a);
            });
          }
        });
        this.total = Number(total) || 1;
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .seltAllbtnDis .cell {
  visibility: hidden;
}

.managefirmstyle {
  max-height: 370px;
  overflow: auto;
}

.pagination {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-wrap: wrap;
}

.buttonClass {
  margin-left: -60px;
}

.mt-20 {
  margin-top: 20px;
}
</style>
