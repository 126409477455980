<template>
  <div class="store-list-container">
    <div class="flex">
      <el-form ref="searchForm" :model="tableQueryData" inline label-width="100px">
        <el-form-item label="供应商类型:">
          <el-select v-model="tableQueryData.data.storeType" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="自营" :value="1"></el-option>
            <el-option label="加盟" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商性质:">
          <el-select v-model="tableQueryData.data.storeAttr" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="企业" :value="1"></el-option>
            <el-option label="个人" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属城市:">
          <el-select v-model="tableQueryData.data.cityId" placeholder="请选择" filterable>
            <el-option v-for="(item, i) in citys" :key="i" :label="item.districtName"
              :value="item.districtId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="后台权限:">
          <el-select v-model="tableQueryData.data.isDisable" placeholder="请选择">
            <el-option label="启用" :value="0"></el-option>
            <el-option label="禁用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入网状态:">
          <el-select v-model="tableQueryData.data.enterNetworkStatus" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option label="待提交申请" value="NOT_APPLICATION"></el-option>
            <el-option label="申请审核中" value="REVIEWING"></el-option>
            <el-option label="申请已驳回" value="REVIEW_BACK"></el-option>
            <el-option label="协议待签署" value="AGREEMENT_SIGNING"></el-option>
            <el-option label="业务开通中" value="BUSINESS_OPENING"></el-option>
            <el-option label="真实性验证中" value="AUTHENTICITY_VERIFYING"></el-option>
            <el-option label="申请已完成" value="COMPLETED"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" ">
          <el-select v-model="tableQueryData.data.searchType" placeholder="请选择查询类型" @change="mirType">
            <el-option label="供应商名称" value="1"></el-option>
            <el-option label="供应商ID" value="2"></el-option>
            <el-option label="管理员账号" value="3"></el-option>
            <el-option label="供应商地址" value="4"></el-option>
            <el-option label="所属经营方" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="tableQueryData.data.key" placeholder="输入搜索关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="handleSerch">查询</el-button>
          <el-button icon="el-icon-refresh-left" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="btn">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddStore()">新建供应商</el-button>
      <el-button plain icon="el-icon-upload2" @click="handleExport()">导出</el-button>
    </div>
    <LTable :tableLoading="tableLoading" :tableData="tableData" :tableHeader="tableHeader"
      :tableQueryData="tableQueryData" :total="total" :getTableList="getTableList" :selectTableData.sync="selectTableData"
      isPagination>
      <template slot="pcAuthority" slot-scope="scope">
        <span style="color: #00a199" v-if="scope.row.pcAuthority === 1">启用</span>
        <span style="color: #e0e0e0" v-else-if="scope.row.pcAuthority === 0">禁用</span>
      </template>
      <template slot="administratorPhoneNumber" slot-scope="scope">
        <span :style="{ color: scope.row.pcAuthority === 1 ? '#2861E2' : '#000000' }">{{
          scope.row.administratorPhoneNumber }}</span>
      </template>
      <template slot="enterNetworkStatusName" slot-scope="scope">
        <span>{{ scope.row.enterNetworkStatus == 'NOT_APPLICATION' ? '待提交申请' : scope.row.enterNetworkStatusName }}</span>
      </template>
      <template slot="isDisable" slot-scope="scope">
        <span :class="scope.row.isDisable == 0 ? 'state-success' : 'state-disable'">{{ scope.row.isDisableName }}</span>
      </template>
      <template slot="sampleNum" slot-scope="scope">
        <el-button type="text" @click="handleSampleSite(scope.row)">{{ scope.row.sampleNum || 0 }}</el-button>
      </template>
      <template slot="productNum" slot-scope="scope">
        <el-button type="text" @click="handleProductSite(scope.row)">{{ scope.row.productNum || 0 }}</el-button>
      </template>
      <template slot="firmName" slot-scope="scope">
        <el-button type="text" @click="gotoOtherPageDoor(scope.row)">{{ scope.row.firmName ||
          '' }}</el-button>
      </template>

      <template slot="operate" slot-scope="scope">
        <el-button class="table-btn" type="text" @click="handleStoreSite(scope.row)">供应商设置</el-button>
        <span class="table-btn-line"></span>
        <el-dropdown trigger="click">
          <el-button class="table-btn" type="text">更多</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button class="table-btn" type="text" @click="handleResetPwd(scope.row)">重置密码</el-button>
            </el-dropdown-item>
            <el-dropdown-item><el-button class="table-btn" type="text"
                @click="handleSettled(scope.row)">入网申请</el-button></el-dropdown-item>
            <el-dropdown-item><el-button class="table-btn" type="text"
                @click="handleWithdrawal(scope.row)">提现设置</el-button></el-dropdown-item>
            <el-dropdown-item><el-button class="table-btn" type="text"
                @click="handleSetRules(scope.row)">分账设置</el-button></el-dropdown-item>
            <el-dropdown-item><el-button class="table-btn" type="text"
                @click="showManageFirm(scope.row)">关联经营方</el-button></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!-- <span class="table-btn-line"></span>

        <span class="table-btn-line"></span>
        
        <span class="table-btn-line"></span>
        <el-button class="table-btn" type="text" @click="handleProductSite(scope.row)">产品设置</el-button>
        <span class="table-btn-line"></span>
         -->
      </template>
    </LTable>
    <el-dialog :close-on-click-modal="false" @open="openManageFirm" title="关联经营方" :visible.sync="isShowManageFirm"
      width="800px">
      <ManageFirm @closeManageFirm="closeManageFirm" :storeId="+storeId" :isDisable="isDisable" ref="manageFirm" />
      <template #footer>
        <el-button @click="isShowManageFirm = false; $refs.manageFirm.reset()">取消</el-button>
        <el-button type="primary" @click="confirmManageFirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getStoreQuery, getCitys, getListExport, resetPassword } from '@/api/store';
import ManageFirm from './components/ManageFirm/index.vue'
import { CompatSource } from 'webpack-sources';
import { async } from 'q';
export default {
  name: "StoreList",
  components: {
    ManageFirm
  },
  data() {
    return {
      citys: [],
      region: undefined,
      tableLoading: false,
      tableHeader: [
        // { label: 'selection' },
        { label: '供应商ID', prop: 'storeId', width: '90', align: 'center' },
        { label: '供应商名称', prop: 'storeName', width: '150' },
        { label: '供应商类型', prop: 'storeTypeName', align: 'center', width: '100' },
        { label: '供应商性质', prop: 'storeAttrName', align: 'center', width: '100' },
        { label: '供应商地址', prop: 'address', width: '150' },
        { label: '营业日', prop: 'businessDay' },
        { label: '营业时间', prop: 'workTime', align: 'center', width: '140' },
        { label: '管理员账号', prop: 'administratorPhoneNumber', align: 'center', width: '140' },
        { label: '所属经营方', prop: 'firmName', align: 'center', width: '140', render: true },
        { label: '关联样片数量', prop: 'sampleNum', align: 'center', width: '100', render: true },
        { label: '关联产品数量', prop: 'productNum', align: 'center', width: '100', render: true },

        { label: '后台权限', prop: 'isDisable', align: 'center', width: '80', render: true },
        { label: '入网状态', prop: 'enterNetworkStatusName', align: 'center', width: '120', render: true },
        { label: '操作', prop: 'operate', align: 'center', width: '160', render: true, fixed: 'right' },
      ],
      total: 0,
      storeId: 1,
      tableQueryData: {
        pageNumber: 1,
        pageSize: 10,
        data: {
          export: 2,
          cityId: undefined,
          isDisable: undefined,
          storeName: undefined,
          key: undefined,
          storeType: undefined,
          enterNetworkStatus: undefined,
        },
      },
      tableData: [], //供应商分页列表
      selectTableData: [],
      isShowManageFirm: false,
      isDisable: 0
    };
  },
  created() {
    this.tableQueryData.data.key = this.$route.params.key;
    this.tableQueryData.data.searchType = this.$route.params.searchType;

    this.tableQueryData.data.productId = this.$route.query.productId;
    if (this.$route.query.sampleId) this.tableQueryData.data.sampleIds = [this.$route.query.sampleId];
    this.getTableList();
    this.getData();
  },
  methods: {
    // 操作成功 提示
    successTips() {
      this.$message({
        message: '操作成功',
        type: 'success'
      });
    },
    // 所属经营方跳转
    gotoOtherPageDoor(data) {
      if (data.firmName) {
        this.$router.push({
          path: '/operateParty',
          name: 'operateParty',
          params: {
            firmId: data.firmId
          },
        })
      }
    },
    showManageFirm(row) {
      const { storeId, isDisable } = row
      this.storeId = storeId
      this.isDisable = isDisable
      this.isShowManageFirm = true
    },
    handleSetRules(row) {
      let data = {
        storeId: row.storeId,
        storeName: row.storeName,
        storeCode: row.storeCode,
      };
      this.$router.push({ path: 'rules', query: data });
    },
    handleSettled(row) {
      let url = row.storeAttr == 2 ? 'addPersonalApply' : 'addEnterpriseApply';
      let data = {
        storeId: row.storeId,
        storeName: row.storeName,
        storeTypeName: row.storeTypeName,
        storeAttrName: row.storeAttrName,
        enterNetworkStatus: row.enterNetworkStatus,
        enterNetworkStatusName: row.enterNetworkStatusName,
      };
      this.$router.push({ path: url, query: data });
    },
    handleWithdrawal(row) {
      // const h = this.$createElement;
      // if (row.enterNetworkStatus != 'COMPLETED') {
      //   this.$msgbox({
      //     title: '操作提示',
      //     message: h('p', null, [h('div', { style: 'color: #444;fontSize:18px;font-weight: bold;' }, '请先完成入网申请操作'), h('div', { style: 'color: #999' }, '完成申请后才可添加提现账号！')]),
      //   });
      //   return;
      // }
      let data = {
        storeId: row.storeId,
        storeName: row.storeName,
        storeTypeName: row.storeTypeName,
        storeAttrName: row.storeAttrName,
        storeAttr: row.storeAttr,
        enterNetworkStatus: row.enterNetworkStatus,
        enterNetworkStatusName: row.enterNetworkStatusName,
      };
      this.$router.push({ path: 'withdrawalSite', query: data });
    },
    async getData() {
      this.citys = (await getCitys({ data: { districtType: 2, status: 1 } })).data || [];
    },
    async getTableList() {
      try {
        this.tableLoading = true;
        let query = JSON.parse(JSON.stringify(this.tableQueryData));
        query.data.cityId = parseInt(this.tableQueryData.data.cityId) || undefined;

        let { data } = await getStoreQuery(query);
        this.total = Number(data.total);
        this.tableData = data.content;
        this.tableLoading = false;
      } catch (error) {
        this.tableLoading = false;
      }
    },
    mirType() {
      this.$forceUpdate()
      this.tableQueryData.data.key = ''
    },
    handleSerch() {
      this.tableQueryData.pageNumber = 1;
      this.getTableList();
    },
    handleReset() {
      Object.assign(this.$data.tableQueryData, this.$options.data().tableQueryData);
      this.getTableList();
    },
    handleAddStore() {
      this.$router.push({ path: '/addStore' });
    },
    async handleExport() {
      this.tableLoading = true;
      let data = JSON.parse(JSON.stringify(this.tableQueryData));
      data.data.export = 1;
      getListExport(data)
        .then((res) => {
          this.tableLoading = false;
          const link = document.createElement('a');
          link.style.display = 'none';
          link.target = 'view_window';
          link.href = URL.createObjectURL(res);
          link.download = '供应商列表.xlsx'; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    handleResetPwd(scopedData) {
      this.$confirm(`是否确认重置密码？（重置后密码为<strong style='color: red; padding: 0 2px'>dp123456</strong>）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(async () => {
          await resetPassword({ data: { storeId: scopedData.storeId } });
          this.$notify.success({ title: '成功', message: '重置密码成功', duration: 2000 });
        })
        .catch(() => { });
    },
    handleStoreSite(scopedData) {
      const pdUrl = `${window.location.origin}/index/storefront/updateStore?id=${scopedData.storeId}`;
      window.open(pdUrl);
    },
    handleProductSite(scopedData) {
      const pdUrl = `${window.location.origin}/index/storefront/siteProduct?id=${scopedData.storeId}`;
      window.open(pdUrl);
    },
    handleSampleSite(row) {
      const pdUrl = `${window.location.origin}/index/storefront/siteSample?id=${row.storeId}&cityId=${row.cityId}`;
      window.open(pdUrl);
    },
    closeManageFirm() {
      this.successTips()
      this.isShowManageFirm = false
    },
    openManageFirm() {
      this.$nextTick(() => {
        this.$refs.manageFirm.newReset()
        this.$refs.manageFirm.getData()
      })
    },
    confirmManageFirm() {
      this.$nextTick(async () => {
        await this.$refs.manageFirm.connectFirm()
        this.getTableList()
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  background-color: #2861e2;
  border-color: #2861e2;
}

.store-list-container {
  background-color: #fff;
  padding: 16px;

  .el-dialog__footer {
    padding: 0 20px 20px 20px;
  }

  .search-form-container {
    justify-content: space-between;
  }
}

.btn {
  padding-bottom: 16px;
}
</style>
