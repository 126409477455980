import request from '@/utils/request'

// 列表
export function operateList(data = {}) {
  return request({
    url: 'supplier-web-api/admin/firm/pageList',
    method: 'post',
    data,
  });
}
// 详情
export function operateDetail(data) {
  return request({
    url: 'supplier-web-api/admin/firm/findById',
    method: 'post',
    data,
  });
}
// 新增
export function operateAdd(data) {
  return request({
    url: 'supplier-web-api/admin/firm/add',
    method: 'post',
    data,
  });
}
// 更新
export function operateUpdate(data) {
  return request({
    url: 'supplier-web-api/admin/firm/updateById',
    method: 'post',
    data,
  });
}
// 停启用
export function startAndstop(data) {
  return request({
    url: 'supplier-web-api/admin/firm/enableById',
    method: 'post',
    data,
  });
}

// 添加门店
export function addFirmStoreList(data) {
  return request({
    url: 'supplier-web-api/admin/firm/addFirmStoreList',
    method: 'post',
    data,
  });
}
// 关联经营方
export function addStoreFirm(data) {
  return request({
    url: 'supplier-web-api/admin/firm/addStoreFirm',
    method: 'post',
    data,
  });
}